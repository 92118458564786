import { useEffect, useState, useMemo, useCallback } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { categoryBar, timeRangeBar } from "layouts/info/styles/chart.styles";
// i18n
import { useTranslation } from "react-i18next";
import BiometricsService from "services/biometrics.service";
import { isNotNil, exportDataDate, roundNumber, exportDataDateV2 } from "helpers/utils";
import { HealthData } from "types/measurement-schema";
import { convertBloodSugar, dateStartByV2, fillUpArrayV2 } from "helpers/infoUtil";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import MDCircularProgress from "components/MDCircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BiometricsType, TimeRange } from "types/enums";
import HorizontalStackBarChart from "examples/Charts/BarCharts/HorizontalStackBarChart";
import ReportService from "services/report.service";
import { Summary } from "types/report";
import { set } from "date-fns";
const category = [
    BiometricsType.BLOOD_PRESSURE,
    BiometricsType.BLOOD_SUGAR,
    BiometricsType.PULSE,
    BiometricsType.BLOOD_OXYGEN,
    BiometricsType.TEMPERATURE,
];
const timeRange = [Summary.LastSeveralDays, Summary.LastFourteenDays, Summary.LastThirtyDays];

interface Prop {
    wistronId: string;
    setRoomData: (data: any) => void;
    setPLoading: (data: any) => void;
}

interface DateData {
    date: string;
    data: number;
}

export const HealthDataChart: React.FC<Prop> = ({ wistronId,setRoomData,setPLoading }) => {
    const [activeCategory, setActiveCategory] = useState<BiometricsType>(category[0]);
    const [activeTimeRange, setTimeRange] = useState<Summary>(timeRange[0]);
    const [chartData, setChartData] = useState(null);
    const [finalList, setFinalList] = useState<DateData[][]>(null);
    const [options, setOptions] = useState({});
    const [data, setData] = useState<any>(null);
    const [label, setLabel] = useState([]);
    const { t: translate } = useTranslation(["common"]);
    const [loading, setLoading] = useState(false);

    const fetchSummary = async () => {
        //get the timezone
        setLoading(true);
        
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const res = await ReportService.getSummaryChart(wistronId, timeZone, activeTimeRange);
        if (res.success) {
            setFinalList(null);
            setChartData(null);
            setData(res.data);
            setRoomData(res.data);
            setPLoading(false);
        }else{
            setLoading(false);
            setRoomData(null);
            setPLoading(false);
        }
    };

    const skipped = (ctx: { p0: { skip: any }; p1: { skip: any } }, value: number[]) =>
        ctx.p0.skip || ctx.p1.skip ? value : undefined;

    const handleChange = useCallback((event: SelectChangeEvent) => {
        setTimeRange(event.target.value as Summary);
    }, []);

    useEffect(() => {
        if (!isNotNil(data)) return;
        const roomNames = Object.keys(data.roomStats).sort();
        if (roomNames.length === 0) return;
        let date = exportDataDateV2(activeTimeRange);
        setLabel(date);
        const _data: { [key: string]: { [key: string]: any } } = {}; // Adjusted to store date-based data
        let startDate: Date = dateStartByV2(activeTimeRange);
        let endDate: Date = new Date();
        endDate.setDate(endDate.getDate() - 1);
        roomNames.forEach((roomName) => {
            const roomData = data.roomStats[roomName];
            if (!_data[roomName]) {
                _data[roomName] = {}; // Initialize the room entry as an object if not already
            }

            switch (activeTimeRange) {
                case "lastSevenDays":
                    startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 6);
                    while (startDate <= endDate) {
                        _data[roomName][startDate.toISOString().substring(0, 10)] = {};
                        startDate.setDate(startDate.getDate() + 1);
                    }
                    break;

                case "lastFourteenDays":
                    startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 13);
                    while (startDate <= endDate) {
                        _data[roomName][startDate.toISOString().substring(0, 10)] = {};
                        startDate.setDate(startDate.getDate() + 1);
                    }
                    break;

                case "lastThirtyDays":
                    startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate() + 1);
                    while (startDate <= endDate) {
                        _data[roomName][startDate.toISOString().substring(0, 10)] = {};
                        startDate.setDate(startDate.getDate() + 1);
                    }
                    break;

                default:
                    startDate = new Date();
            }

            roomData.forEach((item: any) => {
                const date = new Date(item.date).toLocaleString().substring(0, 10).replaceAll("/", "-");
                if (_data[roomName][date]) {
                    if (item.data) {
                        _data[roomName][date] = item.data;
                    }
                }
            });
        });

        //set Chart Data
        let _chartData: any = [];
        roomNames.forEach((roomName) => {
            let _roomData: any = [];
            Object.keys(_data[roomName]).forEach((date) => {
                const value = _data[roomName][date];
                console.log("1231", value);

                // Only push non-empty values
                if (value > 0) {
                    _roomData.push(value / 60 / 60);
                } else {
                    _roomData.push(null);
                }
            });
            _chartData.push({
                label: translate(`device.roomType.${roomName}`),
                color: getColor(roomName),
                data: _roomData,
            });
        });
        console.log(_chartData);
        setChartData(_chartData);
        setLoading(false);
    }, [data]);
    const getColor = (index: string) => {
        switch (index) {
            case "bathRoom":
                return "#BA8CE7";
            case "livingRoom":
                return "#96CB71";
            case "bedRoom":
                return "#F7AD53";
            case "sleep":
                return "#FF5900";
            case "kitchen":
                return "#9C27B0";
            default:
                return "#16C0E8";
        }
    };

    useEffect(() => {
        fetchSummary();
    }, [activeTimeRange]);

    const horizontalStackBarChartProps = useMemo(
        () => ({
            labels: label,
            datasets: chartData ? chartData : [],
            options: {
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
            },
        }),
        [label, chartData],
    ); // Only recompute when label or chartData change

    return (
        <Card sx={{ p: 3 }}>
            <MDBox display="flex" justifyContent="end" mt={3}>
                <FormControl>
                    <Select sx={{ p: 0.5 }} value={activeTimeRange} onChange={handleChange}>
                        {timeRange.map((item, i) => (
                            <MenuItem key={i} value={item} sx={{ minWidth: 50 }}>
                                <MDBox display="flex" alignItems="center">
                                    <MDTypography
                                        variant="subtitle2"
                                        sx={(theme: any) => timeRangeBar(theme, { activeTimeRange, item })}>
                                        {translate(`report.summaryType.${item}`)}
                                    </MDTypography>
                                </MDBox>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </MDBox>
            <MDBox mt={4}>
                {!loading ? (
                    <HorizontalStackBarChart chart={horizontalStackBarChartProps} />
                ) : (
                    <MDCircularProgress size={36} />
                )}
            </MDBox>
        </Card>
    );
};

export default HealthDataChart;
