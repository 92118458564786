import { Response, default as http } from "http-common";
import { Summary } from "types/report";
import { User } from "types/user-schema";

class ReportService {
    async getSummaryReport(date: any, timezone: any, page: any): Promise<Response<any[]>> {
        const response = await http.post<Response<any[]>>("/api/v1/report/summaryReport", {
            page: page,
            date: date,
            timezone: timezone,
        });
        return response.data;
    }

    async getSummary(id: string, timezone: any, type: Summary): Promise<Response<any>> {
        const response = await http.get<Response<any>>(
            `/api/v1/report/generateReport?wistron_id=${id}&timezone=${timezone}&type=${type}`,
        );
        return response.data;
    }

    async getSummaryChart(id: string, timezone: any, type: Summary): Promise<Response<any>> {
        const response = await http.get<Response<any>>(
            `/api/v1/report/generateReportChart?wistron_id=${id}&timezone=${timezone}&type=${type}`,
        );
        return response.data;
    }
}

export default new ReportService();
