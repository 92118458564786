import React, { useMemo, useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import configs from "examples/Charts/LineCharts/ModLineChart/configs";
import colors from "assets/theme/base/colors";
import { Chart, ChartConfiguration,registerables  } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.register(annotationPlugin);
Chart.register(...registerables);
interface Props {
    icon?: {
        color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
        component: React.ReactNode;
    };
    title?: string;
    description?: string | React.ReactNode;
    height?: string | number;
    chart: {
        labels: string[];
        datasets: {
            label: string;
            color: string;
            data: number[];
        }[];
        options?: any;
    };
    [key: string]: any;
}

export const ModLineChart: React.FC<Props> = ({ icon, title, description, height, chart }) => {
    const chartRef = useRef<Chart | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const chartDatasets = chart.datasets
        ? chart.datasets.map((dataset) => ({
              tension: 0.4,
              pointRadius: 3,
              borderWidth: 1,
              backgroundColor: "transparent",
              fill: true,
              pointBackgroundColor: dataset.color,
              borderColor: dataset.color,
              maxBarThickness: 6,
              ...dataset,
          }))
        : [];

    const { data, options } = configs(chart.labels || [], chartDatasets, chart.options);

    useEffect(() => {
        if (canvasRef.current) {
            const chartConfig: ChartConfiguration = {
                type: 'line',
                data: data,
                //@ts-ignore
                options: options,
            };

            chartRef.current = new Chart(canvasRef.current, chartConfig);

            return () => {
                if (chartRef.current) {
                    chartRef.current.destroy();
                    chartRef.current = null;
                }
            };
        }
    }, [data, options]);

    const renderChart = (
        <MDBox py={2} pr={2} pl={icon?.component ? 1 : 2}>
            {title || description ? (
                <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
                    {icon?.component && (
                        <MDBox
                            width="4rem"
                            height="4rem"
                            bgColor={icon.color || "info"}
                            variant="gradient"
                            coloredShadow={icon.color || "info"}
                            borderRadius="xl"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            mt={-5}
                            mr={2}
                        >
                            <Icon fontSize="medium">{icon.component}</Icon>
                        </MDBox>
                    )}
                    <MDBox mt={icon?.component ? -2 : 0}>
                        {title && <MDTypography variant="h6">{title}</MDTypography>}
                        <MDBox mb={2}>
                            <MDTypography component="div" variant="button" color="text">
                                {description}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            ) : null}
            <MDBox height={height}>
                <canvas ref={canvasRef} />
            </MDBox>
        </MDBox>
    );

    return title || description ? <Card>{renderChart}</Card> : renderChart;
};

ModLineChart.defaultProps = {
    icon: { color: "info", component: "" },
    title: "",
    description: "",
    height: "19.125rem",
};

export default ModLineChart;
