import React, { useEffect, useRef } from "react";
import { Chart, ChartConfiguration, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import configs from "./configs";

Chart.register(...registerables);

interface Props {
    icon?: {
        color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
        component: React.ReactNode;
    };
    title?: string;
    description?: string | React.ReactNode;
    height?: string | number;
    chart: {
        labels: string[];
        datasets: {
            label: string;
            color: string;
            data: number[];
        }[];
        options?: any;
    };
    [key: string]: any;
}

const HorizontalStackBarChart: React.FC<Props> = ({ icon, title, description, height, chart }) => {
    const chartRef = useRef<Chart | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const chartDatasets = chart.datasets
    // const { data, options } = configs(chart.labels || [], chartDatasets, chart.options);
    useEffect(() => {
        if (canvasRef.current) {
            const chartConfig: ChartConfiguration = {
                type: 'bar',
                data: {
                    labels: chart.labels,
                    datasets: chart.datasets.map((dataset) => ({
                        ...dataset,
                        backgroundColor: dataset.color,
                    })),
                },
                options: {
                    ...chart.options,
                    indexAxis: 'y',
                    maintainAspectRatio: true,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                        },
                    },
                },
            };

            if (chartRef.current) {
                chartRef.current.destroy();
            }

            chartRef.current = new Chart(canvasRef.current, chartConfig);

            return () => {
                if (chartRef.current) {
                    chartRef.current.destroy();
                    chartRef.current = null;
                }
            };
        }
    }, [chart]);

    return (
        <Card>
            <MDBox py={2} pr={2} pl={icon?.component ? 1 : 2}>
                {title || description ? (
                    <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
                        {icon?.component && (
                            <MDBox
                                width="4rem"
                                height="4rem"
                                bgColor={icon.color || "info"}
                                variant="gradient"
                                coloredShadow={icon.color || "info"}
                                borderRadius="xl"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                color="white"
                                mt={-5}
                                mr={2}
                            >
                                <Icon fontSize="medium">{icon.component}</Icon>
                            </MDBox>
                        )}
                        <MDBox mt={icon?.component ? -2 : 0}>
                            {title && <MDTypography variant="h6">{title}</MDTypography>}
                            <MDBox mb={2}>
                                <MDTypography component="div" variant="button" color="text">
                                    {description}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                ) : null}
                <MDBox height={height}>
                    <canvas ref={canvasRef} />
                </MDBox>
            </MDBox>
        </Card>
    );
};
HorizontalStackBarChart.defaultProps = {
    icon: { color: "info", component: "" },
    title: "",
    description: "",
    // height: "32.125rem",
};

export default HorizontalStackBarChart;