import { useEffect, useState, useMemo, useCallback } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { categoryBar, timeRangeBar } from "layouts/info/styles/chart.styles";
// i18n
import { useTranslation } from "react-i18next";
import BiometricsService from "services/biometrics.service";
import { isNotNil, exportDataDate, roundNumber } from "helpers/utils";
import { HealthData } from "types/measurement-schema";
import { convertBloodSugar, dateStartBy, fillUpArray } from "helpers/infoUtil";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import MDCircularProgress from "components/MDCircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BiometricsType, TimeRange } from "types/enums";

const category = [
    BiometricsType.BLOOD_PRESSURE,
    BiometricsType.BLOOD_SUGAR,
    BiometricsType.PULSE,
    BiometricsType.BLOOD_OXYGEN,
    BiometricsType.TEMPERATURE,
];
const timeRange = [TimeRange.WEEK, TimeRange.TWO_WEEK, TimeRange.MONTH, TimeRange.YEAR];

interface Prop {
    residentId: string;
    diabetes: boolean;
}

interface DateData {
    date: string;
    data: number;
}

export const HealthDataChart: React.FC<Prop> = ({ residentId, diabetes }) => {
    const [activeCategory, setActiveCategory] = useState<BiometricsType>(category[0]);
    const [activeTimeRange, setTimeRange] = useState<string>(timeRange[0]);
    const [chartData, setChartData] = useState(null);
    const [finalList, setFinalList] = useState<DateData[][]>(null);
    const [options, setOptions] = useState({});
    const [data, setData] = useState<HealthData[]>(null);
    const [label, setLabel] = useState([]);
    const { t: translate } = useTranslation(["common"]);

    const fetchBiometrics = useCallback(async () => {
        let startDate: Date = dateStartBy(activeTimeRange);
        startDate.setHours(0, 0, 0, 0);
        const res = await BiometricsService.getAllBiometricsByResident(
            residentId,
            `${startDate.toJSON().slice(0, 10)}T00:00:00`,
        );
        if (res.success) {
            setFinalList(null);
            setChartData(null);
            setData(res.data);
        }
    }, [activeTimeRange]);

    const calculateStats = useCallback((cate: BiometricsType, data: any[], data2?: any[]) => {
        const values = data.filter((obj) => obj.data !== 0 && !isNaN(obj.data)).map((obj) => obj.data);
        const sum = values.reduce((acc, value) => acc + value, 0);
        const average = sum / values.length;
        const highest = Math.max(...values);
        const lowest = Math.min(...values);
        if (cate === BiometricsType.BLOOD_PRESSURE && data2) {
            const values2 = data2.filter((obj) => obj.data !== 0 && !isNaN(obj.data)).map((obj) => obj.data);
            const sum2 = values2.reduce((acc, value) => acc + value, 0);
            const average2 = sum2 / values2.length;
            const highest2 = Math.max(...values2);
            const lowest2 = Math.min(...values2);

            return [
                {
                    average: average,
                    highest: highest,
                    lowest: lowest,
                },
                {
                    average: average2,
                    highest: highest2,
                    lowest: lowest2,
                },
            ];
        }

        return {
            average: average,
            highest: highest,
            lowest: lowest,
        };
    }, []);

    const skipped = (ctx: { p0: { skip: any }; p1: { skip: any } }, value: number[]) =>
        ctx.p0.skip || ctx.p1.skip ? value : undefined;

    const avgBox = useMemo(() => {
        let selectedCate = activeCategory;
        let avg, high, low;
        console.log(finalList, "finalList");
        if(!finalList){
            return null;
        }
        if (selectedCate === BiometricsType.BLOOD_PRESSURE && finalList[1]) {
            const data: any = calculateStats(selectedCate, finalList[0], finalList[1]);
            avg = `${Math.floor(data[1].average)}/${Math.floor(data[0].average)}`;
            high = `${data[1].highest}/${data[0].highest}`;
            low = `${data[1].lowest}/${data[0].lowest}`;
        } else {
            const data: any = calculateStats(selectedCate, finalList[0]);
            if (selectedCate === BiometricsType.BLOOD_SUGAR || selectedCate === BiometricsType.TEMPERATURE) {
                avg = `${roundNumber(data.average, 1)}`;
                high = `${roundNumber(data.highest, 1)}`;
                low = `${roundNumber(data.lowest, 1)}`;
            } else {
                avg = `${roundNumber(data.average, 0)}`;
                high = `${roundNumber(data.highest, 0)}`;
                low = `${roundNumber(data.lowest, 0)}`;
            }
        }

        const renderMeasurement = (label: string, value: string) => (
            <MDBox>
                <MDTypography sx={{ padding: "0.5rem" }}>{translate(label)}</MDTypography>
                <MDBox sx={{ alignItems: "flex-end" }} display="flex">
                    <MDTypography fontWeight="medium" variant="h1">
                        {value}
                    </MDTypography>
                    <MDTypography>{translate(`info.measurement.units.${selectedCate}`)}</MDTypography>
                </MDBox>
            </MDBox>
        );

        return (
            <MDBox display="flex" justifyContent="space-around" width="100%" flexWrap={"wrap"}>
                {renderMeasurement("general.state.average", avg)}
                {renderMeasurement("general.state.high", high)}
                {renderMeasurement("general.state.low", low)}
            </MDBox>
        );
    }, [finalList]);

    const handleChange = useCallback((event: SelectChangeEvent) => {
        setTimeRange(event.target.value);
    }, []);

    useEffect(() => {
        if (!isNotNil(data)) return;
        let selectedCate = activeCategory.split(".")[0];
        let selectedCateData: any[][] = [[]];
        switch (selectedCate) {
            case "bloodPressure":
                selectedCateData[0] = data?.map((_data) => ({
                    date: _data.inputDate,
                    data: _data.diastolicBloodPressure,
                }));
                selectedCateData[1] = data?.map((_data) => ({
                    date: _data.inputDate,
                    data: _data.systolicBloodPressure,
                }));
                break;
            case "bloodSugar":
                selectedCateData[0] = data?.map((_data) => ({
                    date: _data.inputDate,
                    data: convertBloodSugar(_data.bloodSugar.level),
                }));
                break;
            case "pulse":
                selectedCateData[0] = data?.map((_data) => ({
                    date: _data.inputDate,
                    data: _data.pulse,
                }));
                break;
            case "bloodOxygen":
                selectedCateData[0] = data?.map((_data) => ({
                    date: _data.inputDate,
                    data: _data.bloodOxygen,
                }));
                break;
            case "temperature":
                selectedCateData[0] = data?.map((_data) => ({
                    date: _data.inputDate,
                    data: _data.temperature,
                }));
                break;
        }
        setFinalList(selectedCateData);
    }, [activeCategory, data]);

    useEffect(() => {
        if (!isNotNil(finalList)) return;
        let date = exportDataDate(activeTimeRange);
        setLabel(date);
        const _data: any = [];
        let selectedCate = activeCategory;

        const createDataset = (label: string, data: number[], color: string) => {
            return {
                label: label,
                data: data,
                color: color,
                spanGaps: true,
                tension: 0.5,
                borderWidth: 3,
                segment: {
                    borderDash: (ctx: { p0: { skip: any }; p1: { skip: any } }) => skipped(ctx, [4, 4]),
                },
            };
        };

        const createNormalValueDataset = (label: string, value: number, borderColor: string) => {
            const data = Array.from({ length: date.length }, () => value);
            return createDataset(label, data, borderColor);
        };
        const createMeasurementDataset = (label: string, data: any[], borderColor: string) => {
            return createDataset(label, fillUpArray(activeTimeRange, data), borderColor);
        };
        switch (selectedCate) {
            case BiometricsType.BLOOD_PRESSURE:
                setOptions({
                    annotation: {
                        annotations: {
                            line1: {
                                type: "line",
                                yMin: 140,
                                yMax: 140,
                                borderColor: "#F44335",
                                borderWidth: 2,
                            },
                            line2: {
                                type: "line",
                                yMin: 90,
                                yMax: 90,
                                borderColor: "#F3B8F5",
                                borderWidth: 2,
                            },
                            line3: {
                                type: "line",
                                yMin: 90,
                                yMax: 90,
                                borderColor: "#E7E7E7",
                                borderWidth: 2,
                            },
                            line4: {
                                type: "line",
                                yMin: 60,
                                yMax: 60,
                                borderColor: "#4CAF50",
                                borderWidth: 2,
                            },
                        },
                    },
                });
                _data.push(
                    createMeasurementDataset(
                        translate("info.measurement.systolicBloodPressure"),
                        finalList[0],
                        "#7D0FB1",
                    ),
                    createMeasurementDataset(
                        translate("info.measurement.diastolicBloodPressure"),
                        finalList[1],
                        "#303E4A",
                    ),
                );

                break;
            case BiometricsType.BLOOD_SUGAR:
                setOptions({
                    annotation: {
                        annotations: {
                            line1: {
                                type: "line",
                                yMin: 10,
                                yMax: 10,
                                borderColor: "#F44335",
                                borderWidth: 2,
                            },
                            line2: {
                                type: "line",
                                yMin: 5,
                                yMax: 5,
                                borderColor: "#E7E7E7",
                                borderWidth: 2,
                            },
                        },
                    },
                });
                _data.push(
                    createMeasurementDataset(translate("info.measurement.bloodSugar.level"), finalList[0], "#7D0FB1"),
                );
                break;
            case BiometricsType.PULSE:
                setOptions({
                    annotation: {
                        annotations: {
                            line1: {
                                type: "line",
                                yMin: 100,
                                yMax: 100,
                                borderColor: "#F44335",
                                borderWidth: 2,
                            },
                            line2: {
                                type: "line",
                                yMin: 50,
                                yMax: 50,
                                borderColor: "#E7E7E7",
                                borderWidth: 2,
                            },
                        },
                    },
                });
                _data.push(createMeasurementDataset(translate("info.measurement.pulse"), finalList[0], "#7D0FB1"));
                break;

            case BiometricsType.BLOOD_OXYGEN:
                setOptions({
                    annotation: {
                        annotations: {
                            line1: {
                                type: "line",
                                yMin: 95,
                                yMax: 95,
                                borderColor: "#F44335",
                                borderWidth: 2,
                            },
                        },
                    },
                });
                _data.push(
                    createMeasurementDataset(translate("info.measurement.bloodOxygen"), finalList[0], "#7D0FB1"),
                );
                break;
            case BiometricsType.TEMPERATURE:
                setOptions({
                    annotation: {
                        annotations: {
                            line1: {
                                type: "line",
                                yMin: 38.7,
                                yMax: 38.7,
                                borderColor: "#F44335",
                                borderWidth: 2,
                            },
                        },
                    },
                });
                _data.push(
                    createMeasurementDataset(translate("info.measurement.temperature"), finalList[0], "#7D0FB1"),
                );

                break;
        }
        setChartData(_data);
    }, [finalList, activeTimeRange, translate]);

    useEffect(() => {
        fetchBiometrics();
    }, [activeTimeRange]);

    return (
        <Card sx={{ p: 3 }}>
            <MDBox
                display="flex"
                sx={{ overflow: { xs: "hidden", md: "initial" }, overflowX: { xs: "scroll", md: "initial" } }}>
                {category.map((item, i) => (
                    <MDTypography
                        key={i}
                        onClick={() => setActiveCategory(item)}
                        sx={(theme: any) => categoryBar(theme, { activeCategory, item })}>
                        {translate(`info.measurement.${item}`)}
                    </MDTypography>
                ))}
            </MDBox>
            <MDBox display="flex" justifyContent="end" mt={3}>
                <FormControl>
                    <Select sx={{ p: 0.5 }} value={activeTimeRange} onChange={handleChange}>
                        {timeRange.map((item, i) => (
                            <MenuItem key={i} value={item} sx={{ minWidth: 50 }}>
                                <MDBox display="flex" alignItems="center">
                                    <MDTypography
                                        variant="subtitle2"
                                        sx={(theme: any) => timeRangeBar(theme, { activeTimeRange, item })}>
                                        {translate(`general.time.${item}`)}
                                    </MDTypography>
                                </MDBox>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </MDBox>

            <MDBox mt={4}>
                {chartData ? (
                    <DefaultLineChart
                        chart={{
                            labels: label,
                            datasets: chartData,
                            options: options && options,
                        }}
                    />
                ) : (
                    <MDCircularProgress size={36} />
                )}
            </MDBox>
            <MDBox mt={4}>{avgBox}</MDBox>
        </Card>
    );
};

export default HealthDataChart;
