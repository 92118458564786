/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2024 Wecare Dynamics Inc.



 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { isNotNil } from "helpers/utils";

const configs = (labels: any, datasets: any, pluginsoptions?: any) => {
    return {
        data: {
            labels,
            datasets: [...datasets],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                ...pluginsoptions,
                legend: {
                    display: true,  // Show the legend
                    labels: {
                        backgroundColor: (context: any) => context.legendItem?.backgroundColor, // Fill the box with the dataset color
                    },
                },
            },
            interaction: {
                intersect: false,
                mode: "index",
            },
            scales: {
                y: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                        color: "#c1c4ce5c",
                    },
                    ticks: {
                        display: true,  // Show y-axis labels
                        color: "#9ca2b7", // Optional: Set color for labels
                        font: {
                            size: 14,
                            weight: 300,
                            family: "Roboto",
                            style: "normal",
                            lineHeight: 2,
                        },
                    },
                },
                x: {
                    grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: true,
                        borderDash: [5, 5],
                        color: "#c1c4ce5c",
                    },
                    ticks: {
                        display: true,  // Show x-axis labels
                        color: "#9ca2b7", // Optional: Set color for labels
                        font: {
                            size: 14,
                            weight: 300,
                            family: "Roboto",
                            style: "normal",
                            lineHeight: 2,
                        },
                    },
                },
            },
        },
    };
};

export default configs;
