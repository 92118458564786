import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ResidentCardProps } from "layouts/info/schemas/card-schema";
import { Card, Icon } from "@mui/material";

import BasicLayout from "examples/LayoutContainers/BasicLayout";
import MDTab from "components/MDTab";
import MDBox from "components/MDBox";
import MDCircularProgress from "components/MDCircularProgress";
import ResidentCard from "layouts/info/components/ResidentCard";
import RiskCard from "layouts/info/components/RiskCard";
import HealthDataTable from "layouts/info/components/HealthDataTable";
import HealthDataChart from "./HealthDataBarChart";
import Survey from "layouts/info/components/Survey";
import { Risk } from "types/setting-schema";

import { isNotNil, isNil } from "helpers/utils";
import ResidentService from "services/resident.service";
import { useTranslation } from "react-i18next";
import { is } from "date-fns/locale";
import HealthDataLineChart from "./HealthDataLineChart";

import diabetesIcon from "assets/images/icons/risks/diabetes.svg";
import alertIcon from "assets/images/icons/summary/alert.png";
import positionIcon from "assets/images/icons/summary/position.png";
import bathIcon from "assets/images/icons/summary/bath.png";
import bedIcon from "assets/images/icons/summary/bed.png";
import kitchenIcon from "assets/images/icons/summary/kitchen.png";
import outdoorIcon from "assets/images/icons/summary/outdoor.png";
import roomIcon from "assets/images/icons/summary/room.png";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import MDTypography from "components/MDTypography";
import { use } from "i18next";
import MDNoData from "components/MDNoData";

export const ResidentRoomPage: React.FC = () => {
    let state = useLocation().state as { residentId: string; tag?: string } | null;
    const [resident, setResident] = useState<ResidentCardProps | null>(null);
    const [activePage, setActivePage] = useState("measurement");
    const [activeMode, setActiveMode] = useState<string>("list");
    const { t: translate } = useTranslation(["common"]);
    const [roomData, setRoomData] = useState(null);
    const [loading, setLoading] = useState(true);
    const handleChildData = (data: any) => {
        setRoomData(data);
    };
    const handleLoading = (data: boolean) => {
        setLoading(data);
    };
    useEffect(() => {
        if (isNotNil(state.residentId)) {
            fetchResident();
        }
        if (isNotNil(state.tag)) {
            setActiveMode(state.tag);
        }
    }, []);

    const fetchResident = async () => {
        const res = await ResidentService.getSingleResident(state.residentId);
        if (res.success) {
            const {
                _id,
                avatarUrl,
                name,
                dateOfBirth,
                gender,
                address,
                center,
                risks,
                diabetes,
                preferredLanguage,
                wistron_id,
            } = res.data;

            const data = {
                id: _id,
                avatarUrl: isNotNil(avatarUrl) ? avatarUrl : null,
                name: name,
                gender: gender,
                dateOfBirth: dateOfBirth,
                center: isNotNil(center) ? center.name : null,
                address: address,
                risks: risks,
                diabetes: diabetes,
                preferredLanguage: preferredLanguage,
                wistron_id: wistron_id,
            };
            setResident(data);
        }
    };

    const renderToiletSummaryCard = () => {
        //loop the roomData.alert array to count the alert with the alert.status to be the key
        const isEmptyNew = roomData.toilet?.new;
        const isEmptyOld = roomData.toilet?.old;
        const diff = Math.abs(isEmptyNew - (isEmptyOld || 0));
        const upOrDownBoolean = isEmptyNew > (isEmptyOld || 0);
        const normalTimes = 8;
        return (
            <Card sx={{ p: "10px", pb: 2, height: "100%", width: "100%" }}>
                <MDBox sx={{ alignItems: "center" }}>
                    <MDBox width="45px" mb={"6px"}>
                        <img src={bathIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.toilet`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        //paddingTop: "22px",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {isEmptyNew ? (
                        <MDBox
                            sx={{
                                // display: "flex",
                                color: "#303E4A",
                                fontSize: "20px",
                                fontWeight: "700",
                                paddingTop: "8px",
                            }}>
                            <MDBox sx={{ display: "flex", alignItems: "end", justifyContent: "start" }}>
                                <MDBox sx={{ position: "relative" }}>{roomData.toilet?.new}</MDBox>
                            </MDBox>
                        </MDBox>
                    ) : (
                        <MDBox sx={{ color: "#303E4A", fontSize: "20px", fontWeight: "700" }}>
                            {translate(`general.state.noData`)}
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        );
    };
    const renderSleepSummaryCard = () => {
        //cal the sleep time is it > 6h
        const sleepenought = roomData.sleepStatus?.percentage > 6 * 60 * 60;
        return (
            <Card sx={{ p: "10px", pb: 2, height: "100%", width: "100%" }}>
                <MDBox
                    sx={{
                        alignItems: "center",
                    }}>
                    <MDBox width="45px" mb={"6px"}>
                        <img src={bedIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.bedInfo`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        height: "100%",
                        display: "flex",
                        color: "#303E4A",
                        fontSize: "20px",
                        fontWeight: "700",
                        paddingTop: "8px",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "end",
                    }}>
                    <MDBox>
                        {translate(`setting.alarms.restTime`, {
                            value: Number(roomData.sleepStatus?.percentage / 60 / 60).toFixed(0),
                        })}
                    </MDBox>
                    <MDBox sx={{ fontSize: "12px" }}>
                        {translate(`setting.alarms.dailyRestTIme`, {
                            value: Number(
                                roomData.sleepStatus?.percentage / 60 / 60 - roomData.sleepStatus?.nightSleep / 60 / 60,
                            ).toFixed(0),
                        })}
                    </MDBox>
                    <MDBox sx={{ fontSize: "11px" }}>
                        {translate(`setting.alarms.nightRestTime`, {
                            value: Number(roomData.sleepStatus?.nightSleep / 60 / 60).toFixed(0),
                        })}
                    </MDBox>
                </MDBox>
            </Card>
        );
    };
    const renderNightToiletSummaryCard = () => {
        //loop the roomData.alert array to count the alert with the alert.status to be the key
        const isEmptyNew = roomData.toilet?.night;
        const isEmptyOld = roomData.toilet?.oldNight;
        const diff = Math.abs(isEmptyNew - (isEmptyOld || 0));
        const upOrDownBoolean = isEmptyNew > (isEmptyOld || 0);
        const normalTimes = 2;
        return (
            <Card sx={{ p: "10px", pb: 2, height: "100%", width: "100%" }}>
                <MDBox sx={{ alignItems: "center" }}>
                    <MDBox width="45px" mb={"6px"}>
                        <img src={bathIcon} />
                    </MDBox>

                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.nightToilet`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        //paddingTop: "22px",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {isEmptyNew ? (
                        <MDBox
                            sx={{
                                // display: "flex",
                                color: "#303E4A",
                                fontSize: "20px",
                                fontWeight: "700",
                                paddingTop: "8px",
                            }}>
                            <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <MDBox sx={{ position: "relative" }}>{roomData.toilet?.night}</MDBox>
                            </MDBox>
                        </MDBox>
                    ) : (
                        <MDBox sx={{ color: "#303E4A", fontSize: "20px", fontWeight: "700" }}>
                            {translate(`general.state.noData`)}
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        );
    };
    const renderKitchenSummaryCard = () => {
        //loop the roomData.alert array to count the alert with the alert.status to be the key
        const isEmptyNew = roomData.kitchen?.new;
        const isEmptyOld = roomData.kitchen?.old;
        const diff = Math.abs(isEmptyNew - (isEmptyOld || 0));
        const upOrDownBoolean = isEmptyNew > (isEmptyOld || 0);
        const normalTimes = 8;
        return (
            <Card sx={{ p: "10px", pb: 2, height: "100%", width: "100%" }}>
                <MDBox sx={{ alignItems: "center" }}>
                    <MDBox width="45px" mb={"6px"}>
                        <img src={kitchenIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.kitchenInfo`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        //paddingTop: "22px",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {isEmptyNew ? (
                        <MDBox
                            sx={{
                                // display: "flex",
                                color: "#303E4A",
                                fontSize: "20px",
                                fontWeight: "700",
                                paddingTop: "8px",
                            }}>
                            <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <MDBox sx={{ position: "relative" }}>{roomData.toilet?.new}</MDBox>
                            </MDBox>
                        </MDBox>
                    ) : (
                        <MDBox sx={{ color: "#303E4A", fontSize: "20px", fontWeight: "700" }}>
                            {translate(`general.state.noData`)}
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        );
    };
    const renderOutofRoomSummaryCard = () => {
        const total = roomData.notInRoom?.total;

        return (
            <Card sx={{ p: "10px", pb: 2, height: "100%", width: "100%" }}>
                <MDBox sx={{ alignItems: "center" }}>
                    <MDBox width="45px" mb={"6px"}>
                        <img src={outdoorIcon} />
                    </MDBox>
                    <MDTypography variant="subtitle2" sx={{ color: "#7B809A", fontSize: "16px" }}>
                        {translate(`info.realTimeInfo.notInRoom`)}
                    </MDTypography>
                </MDBox>
                <MDBox
                    sx={{
                        display: "flex",
                        //paddingTop: "22px",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {total ? (
                        <MDBox
                            sx={{
                                // display: "flex",
                                color: "#303E4A",
                                fontSize: "20px",
                                fontWeight: "700",
                                paddingTop: "8px",
                            }}>
                            <MDBox sx={{ display: "flex", alignItems: "end", justifyContent: "start" }}>
                                <MDBox sx={{ position: "relative" }}>{roomData.toilet?.new}</MDBox>
                            </MDBox>
                        </MDBox>
                    ) : (
                        <MDBox sx={{ color: "#303E4A", fontSize: "20px", fontWeight: "700" }}>
                            {translate(`general.state.noData`)}
                        </MDBox>
                    )}
                </MDBox>
            </Card>
        );
    };
    const renderPage = () => {
        if (isNil(resident)) {
            return <MDCircularProgress />;
        }
        if (!loading && roomData == null) {
            return (
                <MDNoData
                    content={translate("general.notification.noData", {
                        type: `${translate("general.type.activity")}`,
                    })}
                />
            );
        }
        return (
            <>
                <MDBox display="flex" justifyContent="space-between" flexDirection={{ xs: "column", md: "row" }} mb={3}>
                    <ResidentCard {...resident} />
                    <MDBox display="flex" flexWrap="wrap" gap="10px">
                        <MDBox width="120px" height="158px">
                            {roomData && renderToiletSummaryCard()}
                        </MDBox>
                        <MDBox width="140px" height="158px">
                            {roomData && renderSleepSummaryCard()}
                        </MDBox>
                        <MDBox width="120px" height="158px">
                            {roomData && renderNightToiletSummaryCard()}
                        </MDBox>
                        <MDBox width="120px" height="158px">
                            {roomData && renderKitchenSummaryCard()}
                        </MDBox>
                        <MDBox width="120px" height="158px">
                            {roomData && renderOutofRoomSummaryCard()}
                        </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="end"
                    mb={3}
                    mt={{ xs: 3, md: 0 }}
                    sx={{
                        flexDirection: { xs: "column", md: "row" },
                        rowGap: { xs: 1 },
                        justifyContent: { xs: "flex-end", md: "flex-end" },
                        alignItems: { xs: "center", md: "end" },
                    }}>
                    {activePage === "measurement" && (
                        <MDTab
                            list={[
                                { key: "menu", value: "list" },
                                { key: "insights", value: "chart" },
                            ]}
                            active={activeMode}
                            scrollable={false}
                            isIcon={true}
                            setActive={setActiveMode}
                        />
                    )}
                </MDBox>
                {activeMode === "list" ? (
                    <HealthDataChart
                        wistronId={resident.wistron_id}
                        setRoomData={handleChildData}
                        setPLoading={handleLoading}
                    />
                ) : (
                    <HealthDataLineChart
                        wistronId={resident.wistron_id}
                        setRoomData={handleChildData}
                        setPLoading={handleLoading}
                    />
                )}
            </>
        );
    };

    return <BasicLayout>{renderPage()}</BasicLayout>;
};

export default ResidentRoomPage;
